import React, { useState } from 'react';
import './Login.css';

function Login({onLogin}:any) {

  const [ userName, setUserName ] = useState("");
  const [ playing, setPlaying ] = useState(false);

  const createGame = (e:any) => {
    e.preventDefault()
    if (userName.trim() == "") return
    onLogin(userName)
    setUserName("")
  }

  const play = (e:any) => {
    e.preventDefault()
    if (playing) return;
    try {
      new Audio("/code-names.mp3").play();
      setPlaying(true)
    }
    catch {}
  }

  return (
    <div className='home'>
      <form>
        <h1>Skrytá jména</h1>
        <div>
          <input id="userName" type='text' name="userName" placeholder="Jméno uživatele" value={userName} onChange={ e => setUserName(e.target.value) } ></input>
          <input type='submit' value="OK" onClick={ e => createGame(e) } />
        </div>
        <p><a onClick={e => play(e)}>[ intro ]</a></p>
      </form>
    </div>
  );
}
export default Login;