import React, { useState } from 'react';
import './TeamBox.css';

function TeamBox({color, data}:any) {

    if (data.teams == null) {
        return <div/>
    }
    
    const nextTurnColor: string = data.currentPlayer.color;
    const blink: boolean = nextTurnColor == color && data.currentPlayer.play;

    return <section className={'team-box ' + color + '-team' + (blink ? " blink" : "")}>
        <div className="score">{data.teams[color].remains}</div> 
        <ul>
            <li><span>Operativa </span> {data.teams[color].operative.join(", ")}</li>
            <li><span>Kapitán </span> {data.teams[color].spymaster.join(", ")}</li>
        </ul>
    </section>
}

export default TeamBox;
