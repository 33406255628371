import React from 'react'
import { FaCheckCircle } from "react-icons/fa";
import { FaCrown } from "react-icons/fa6";
import { FaWineGlass } from "react-icons/fa";

const RoleIcon = ({role}:any) => {
    switch (role)
    {
        case "operative":
            return <span className='icon'><FaCheckCircle/></span>
        case "spymaster":
            return <span className='icon'><FaCrown/></span>
        case "win":
            return <span className='icon'><FaWineGlass/></span>
        default:
            return <span />
    }
}

export default RoleIcon