import React, { useEffect, useRef } from 'react';
import './Log.css';
import LogItem from './LogItem';
import ClueBox from './ClueBox';
import AlwaysScrollToBottom from './AlwaysScrollToBottom';

function Log({data, soundTurn, doAction}:any) {
  const history = data.history;

  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);
  const isUserAtBottomRef = useRef<boolean>(true);

  const scrollToBottom = (): void => {
    endOfMessagesRef.current?.scrollIntoView();
  };

  const handleScroll = (): void => {
    const tolerance = 100;
    const distance = listRef.current!.scrollHeight - listRef.current!.scrollTop - listRef.current!.clientHeight;
    const isAtBottom = Math.abs(distance) < tolerance;
    isUserAtBottomRef.current = isAtBottom;
  };

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      if (isUserAtBottomRef.current) {
        scrollToBottom();
      }
    });

    observer.observe(listRef.current as Node, { childList: true });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isUserAtBottomRef.current) {
      scrollToBottom();
    }
  }, [history]);

  useEffect(() => {
    if (soundTurn && data.currentPlayer.play) {
      try {
        new Audio("/notification.mp3").play();
      }
      catch {}
      }
  }, [data.state]);

  return (
    <section className="log">
      <ul onScroll={handleScroll} ref={listRef}>
        {
          history != null ? history.map((i:any, index:number) => <LogItem item={i} key={index} />) : ""
        }
        <div ref={endOfMessagesRef} />
      </ul>
      <ClueBox data={data} doAction={doAction} />
    </section>
  );
}
export default Log;
