import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import Login from './Login';
import Screen from './Screen';
import './App.css';
import Connector from './signalr-connection'
import { Console } from 'console';

function App() {
  const cookies = new Cookies();
  
  const setCookie = (c_name: any, value: any, exdays: any) => {
    cookies.set(c_name, value, { path: '/', expires: new Date(Date.now() + exdays * 86400) });
  }

  const getCookie = (c_name: any) => {
    const val = cookies.get(c_name)
    return val === undefined ? null : val
  }

  const { newMessage, eventsOff, events } = Connector();
  const [data, setData] = useState(null);
  const [messages, setMessages]:any = useState([]);
  const [typingMap, setTypingMap]:any = useState([]);
  const [soundTurn, setSoundTurn]:any = useState(getCookie("soundTurn") == 1);
  const [soundChat, setSoundChat]:any = useState(getCookie("soundChat") == 1);

  
  
  useEffect(() => {
    events(
      // onUpdateStateReceived
      (messageType, message) => {
        const parsed = JSON.parse(message)
        window.history.replaceState(null, "Skrytá jména", "/" + parsed.gameID)
        setData(parsed)
      },
      // onChatMessage
      (messageType, message) => {
        const result = [...messages, ...JSON.parse(message)];
        setMessages(result)
      },
      // typing
      (name, state) => {
        const limit = Date.now() - 2 * 1000;
        if (state == true) {
          const result = [...typingMap.filter((kv:any) => kv.expire > limit && kv.name != name), { name: name, expire: Date.now() }].filter((kv:any) => kv.expire > limit) 
          setTypingMap(result);
        } else {
          const result = typingMap.filter((kv:any) => kv.expire > limit && kv.name != name) 
          setTypingMap(result);
        }
      },
      // onInit
      () => {
        var path = window.location.pathname.replace(/^[/]/g, '')
        setMessages([])
        newMessage("Init", "token|userName|gameID", { token: token, userName: userName, gameID: path});
      }
    );
    return () => {
      eventsOff();
    };
  }, [events, messages, typingMap]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (typingMap.length > 0) {
        const limit = Date.now() - 2 * 1000;
        const result = typingMap.filter((kv:any) => kv.expire > limit);
        setTypingMap(result);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [typingMap]);

  const toggleSound = (type: string) => {
    switch (type) {
      case "turn":
        {
          const state: any = !soundTurn
          setCookie("soundTurn", state ? "1" : "0", 1000)
          setSoundTurn(state);
        }
        break;
      case "chat":
        {
          const state: any = !soundChat
          setCookie("soundChat", state ? "1" : "0", 1000)
          setSoundChat(state);
        }
        break;
    }
  }


  let hasToken = getCookie("token") !== null;
  const [token, setToken] = useState(getCookie("token"));
  const [userName, setUserName] = useState(getCookie("userName"));
  const [inGame, setInGame] = useState(hasToken);

  const genGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    });
  }

  const onLogin = (userName: string) => {
    setUserName(userName)
    setCookie("userName", userName, 1000)

    const tok = genGuid()
    setCookie("token", tok, 1000)
    setToken(tok)

    setInGame(true)
    var path = window.location.pathname.replace(/^[/]/g, '')
    newMessage("Init", "token|userName|gameID", { token: tok, userName: userName, gameID: path})
  }

  const doAction = (actionName:string, data:any) => {
    var path = window.location.pathname.replace(/^[/]/g, '')
    const dataToSend = { ... data, gameID: path, token: token }
    newMessage("command", actionName, dataToSend)
  } 

  const sendMessage = (actionName:string, data:any) => {
    var path = window.location.pathname.replace(/^[/]/g, '')
    const dataToSend = { ... data, gameID: path, token: token, messageID: genGuid() }
    newMessage("chat", actionName, dataToSend)
  } 

  const onWrite = (state:boolean) => {
    var path = window.location.pathname.replace(/^[/]/g, '')
    const dataToSend = { gameID: path, token: token }
    newMessage("writing", state ? "start" : "stop", dataToSend)
  }

  return (
    <>
      {inGame ? <Screen data={data} doAction={doAction} setCookie={setCookie} messages={messages} typingMap={typingMap} soundTurn={soundTurn} soundChat={soundChat} sendMessage={sendMessage} onWrite={onWrite} toggleSound={toggleSound} /> : <Login onLogin={onLogin} />}
    </>
  )

}
export default App;