import React/*, { useEffect, useState }*/ from 'react';
import { FaCheckCircle } from "react-icons/fa";
import './Field.css';

function Field({field, currentPlayer, state, doAction}:any) {
  const revealed = (state.startsWith("win") || currentPlayer.role === "spymaster") && field.revealed;
  const showCheck = currentPlayer.play && currentPlayer.role === "operative" && !field.revealed;
  const toClue = currentPlayer.role === "spymaster" && !field.revealed && currentPlayer.color == field.color;
  const selected = currentPlayer.role === "operative" && !field.revealed && field.tips.length > 0; 
  return (
    <li className={(field.color ?? "none") + (revealed ? " revealed" : "") + (toClue ? " to-clue" : "") + (selected ? " selected" : "")} 
      onClick={ (e) => { doAction("addTip", { fieldIndex: field.index }) } }>
        { showCheck && <div className='check' onClick={ (e) => { doAction("selectWord", { fieldIndex: field.index })} }><FaCheckCircle /></div> }
        <div className='word'>{field.word}</div>
        { !field.revealed && <div className='tips'>{field.tips.join()}</div> }
    </li>
  );
}
export default Field;