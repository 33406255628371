import React/*, { useEffect, useState }*/ from 'react';
import Menu from './Menu';
import TeamBox from './TeamBox';
import WordTable from './WordTable';
import Log from './Log';
import Chat from './Chat';
import './Screen.css';


function Screen({data, doAction, setCookie, messages, typingMap, soundTurn, soundChat, sendMessage, onWrite, toggleSound}:any) {

  if (data == null) return (
    <div />
  )
  return (
    <>
      <Menu data={data} soundTurn={soundTurn} soundChat={soundChat} doAction={doAction} setCookie={setCookie} toggleSound={toggleSound}/>
      <TeamBox color="red" data={data} />
      <TeamBox color="blue" data={data} />
      <WordTable data={data} doAction={doAction} />
      <Log data={data} soundTurn={soundTurn} doAction={doAction} />
      <Chat messages={messages} typingMap={typingMap} soundChat={soundChat} postMessage={sendMessage} onWrite={onWrite} />
    </>
  );
}
export default Screen;
