import React, { useRef, useEffect } from 'react';

const TextLink = ({text}:any) => {
    const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const formatMessage = (message:string):string => {
        return message
        .replace(/:-?[)]/g, '🙂')
        .replace(/:-?[(]/g, '🙁')
        .replace(/:-?D/g, '😀')
        .replace(/;-?[)]/g, '😉')
        .replace(/:-?[O]/g, '😮')
        .replace(/:-?[/]/g, '😕')
      }
    const parts = text
    .split(" ")
    .map((part:any) => URL_REGEX.test(part) ? <a href={part} target="_blank">{part} </a> : formatMessage(part) + " ");
    
    return (
        <>{parts}</>
    )
};

export default TextLink
