import React, { useState } from 'react';
import RoleIcon from './RoleIcon';
import './Menu.css';

function Menu({data, soundTurn, soundChat, doAction, setCookie, toggleSound}:any) {
  
  const nextTurnColorDict: Record<string, string> = {
    redSpymaster: "red",
    blueSpymaster: "blue",
    redOperative: "red",
    blueOperative: "blue",
    winBlue: "blue",
    winRed: "red"
  }
  const nextTurnRoleDict: Record<string, string> = {
    redSpymaster: "spymaster",
    blueSpymaster: "spymaster",
    redOperative: "operative",
    blueOperative: "operative",
    winBlue: "win",
    winRed: "win"
  }

  if (data.currentPlayer == null) {
    return ( 
      <div/>
    )
  }

  const state = data.state;
  const currentPlayer = data.currentPlayer;
  const nextTurnColor: string = nextTurnColorDict[state] || "?";
  const nextTurnRole: string = nextTurnRoleDict[state] || "?";

  const wordSelection: boolean = (state === 'redOperative' && currentPlayer.role === 'operative' && currentPlayer.color === 'red') ||
    (state === 'blueOperative' && currentPlayer.role === 'operative' && currentPlayer.color === 'blue');

  return (
    <section className='menu'>
      <div className="left">
          <div className={"current-player current-player-" + data.currentPlayer.color + (currentPlayer.play ? " play" : "")} title="Vaše barva a role">
              <RoleIcon role={data.currentPlayer.role} /> {data.currentPlayer.name}
          </div>
          <div className={"plays plays-" + nextTurnColor} title="Barva a role hráče, který je právě na tahu">
            <RoleIcon role={nextTurnRole} />
          </div>
      </div>
      <div className="right">
      <label htmlFor="soundTurn">Zvuk na tahu</label>
      <input id="soundTurn" type="checkbox" checked={soundTurn} title="Zapnutý zvuk - na tahu" onClick={ () => toggleSound("turn") }></input>
      <label htmlFor="soundChat">zpráva</label>
      <input id="soundChat" type="checkbox" checked={soundChat} title="Zapnutý zvuk - nová zpráva" onClick={ () => toggleSound("chat") }></input>
        {
          data.currentPlayer.role == "none" ? <>
            <button title="Stát se červeným kapitánem" onClick={ () => doAction("setPlayerRole", { role: "spymaster", color: "red" }) }>Červený<br/>kapitán</button>
            <button title="Stát se červeným operativcem (hádačem)" onClick={ () => doAction("setPlayerRole", { role: "operative", color: "red" }) }>Červená<br/>operativa</button>
            <button title="Stát se modrým kapitánem" onClick={ () => doAction("setPlayerRole", { role: "spymaster", color: "blue" }) }>Modrý<br/>kapitán</button>
            <button title="Stát se modrým operativcem (hádačem)" onClick={ () => doAction("setPlayerRole", { role: "operative", color: "blue" }) }>Modrá<br/>operativa</button>
            <button title="Odhlásit se/zvolit si jinou přezdívku" onClick={ () => {
              setCookie("userName", null, -1);
              setCookie("token", null, -1);
              window.location.reload();
            } }>Odhlásit<br/>se</button>
          </> : (data.currentPlayer.role == "operative" && data.teams[data.currentPlayer.color].spymaster.length == 0 ? <>
            <button title="Stát se kapitánem svého týmu" onClick={ () => doAction("setPlayerRole", { role: "spymaster", color: data.currentPlayer.color }) }>Kapitán</button>
            <button title="Odejít z týmu a pouze přihlížet" onClick={ () => doAction("setPlayerRole", { role: "none", color: "none" }) }>Přihlížet</button>
          </> : <button title="Odejít z týmu a pouze přihlížet" onClick={ () => doAction("setPlayerRole", { role: "none", color: "none" }) }>Přihlížet</button>)
        }
        {
          wordSelection && <button title="Ukončit tah" className="end-turn" onClick={ () => doAction("endMove", { }) }>Konec tahu</button>
        }
        <button title="Ukončit hru a začít novou s novými slovy" onClick={ () => { if (window.confirm("Opravdu resetovat hru?")) { doAction("resetGame", { }) } }}>Reset<br/>hry</button>
      </div>
    </section>
  );
}

export default Menu;
