import React/*, { useEffect, useState }*/ from 'react';
import './LogItem.css';

function LogItem({item}:any) {
  return (
    <li className={"user-" + item.playerColor} title={item.dateTime}>
        <span className='userName'>{item.userName}</span> {item.action} <span className={item.action == "napovídá" ? "help" : ""}><span className={"word word-" + item.wordColor}>{item.word}</span> <span className='number'>{item.number}</span></span>
    </li>
  );
}
export default LogItem;
