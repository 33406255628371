import React, { useState, useRef } from 'react';
import RoleIcon from './RoleIcon';
import './ClueBox.css';

const ClueBox = ({ data, doAction }: any) => {

  const [ word, setWord ] = useState("")
  const [ number, setNumber ] = useState("")
  const numberInput = useRef<HTMLInputElement>(null);

  const currentPlayer = data.currentPlayer;
  const state = data.state;

  const sendWord = (e:any) => {
    e.preventDefault()
    if (/^[0-9Nn]$/.test(number) && word.length > 0 && (/^[Nn]$/.test(number) || (parseInt(number) <= currentPlayer.remains && parseInt(number) >= 0))) {
      doAction("giveClue", { word: word, number: number });
      setNumber("");
      setWord("");
    }
  }

  const wordWrite: boolean = (state === 'redSpymaster' && currentPlayer.role === 'spymaster' && currentPlayer.color === 'red') ||
    (state === 'blueSpymaster' && currentPlayer.role === 'spymaster' && currentPlayer.color === 'blue');
  const wordSelection: boolean = data.clue != null;
  const winRed: boolean = data.state == "winRed";
  const winBlue: boolean = data.state == "winBlue";
  const inTeam: boolean = data.currentPlayer.role != "none";
  const onTurn: string = state.startsWith("red") ? "red" : (state.startsWith("blue") ? "blue" : "none" )
  return (
    <div className="clue-box">
      {wordSelection && <span className={'clue ' + onTurn}>{data.clue.word} {data.clue.number}</span>}
      {wordWrite && <form onSubmit={ e => e.preventDefault() }>
          <input 
            type="text"
            name="word" 
            value={word} 
            autoComplete="off" 
            onChange={ (e) => setWord(e.target.value) } 
            onKeyDown={ (e) => { if(e.key == "Enter") { e.preventDefault(); numberInput.current?.focus() } } } />
          <input 
            type="text"
            name="number"
            ref={numberInput} 
            value={number} 
            title="n = nekonečno"
            onChange={ (e) =>setNumber(e.target.value) }
            onKeyDown={ (e) => { if(e.key == "Enter") { sendWord(e) } } } />
          <input type="button" value="Napovědět" onClick={ (e) => sendWord(e) } />
        </form>
      }
      {winRed && <span>Červený tým vyhrál</span>}
      {winBlue && <span>Modrý tým vyhrál</span>}
      {(!wordSelection && !wordWrite && !winRed && !winBlue && inTeam) && <span className={'wait ' + onTurn}>Čeká se na nápovědu</span>}
      {(!wordSelection && !wordWrite && !winRed && !winBlue && !inTeam) && <span>Zvolte tým</span>}
    </div>
  )
}

export default ClueBox
