import React, { useState, useEffect, useRef } from 'react';
import './Chat.css';
import TextLink from './TextLink';
import AlwaysScrollToBottom from './AlwaysScrollToBottom';
import { FaCrown } from "react-icons/fa6";
import { FaQuestionCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { FaWindowClose } from "react-icons/fa";
import { Console } from 'console';

const Chat = ({messages, typingMap, soundChat, postMessage, onWrite}:any) => {

  const [ message, setMessage ] = useState("")
  const [ replyMessage, setReplyMessage ] = useState<any>(null)
  const [ typing, setTyping ] = useState(false)
  const chatInput = useRef<HTMLInputElement>(null);

  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);
  const isUserAtBottomRef = useRef<boolean>(true);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView();
  };

  const handleScroll = () => {
    const tolerance = 350;
    const distance = listRef.current!.scrollHeight - listRef.current!.scrollTop - listRef.current!.clientHeight;
    const isAtBottom = Math.abs(distance) < tolerance;
    console.log(distance);
    isUserAtBottomRef.current = isAtBottom;
  };

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      if (isUserAtBottomRef.current) {
        scrollToBottom();
      }
    });

    observer.observe(listRef.current as Node, { childList: true });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isUserAtBottomRef.current) {
      scrollToBottom();
    }
  }, [messages]);


  useEffect(() => {
    if (soundChat) {
      try {
        new Audio("/chat-message.mp3").play();
      }
      catch {}
    }
  }, [messages]);

  const sendMessage = (e:any) => {
    e.preventDefault()
    if (message.trim() != "") {
      postMessage("newMessage", { message: message, quoteID: replyMessage ? replyMessage.id : null })
      setMessage("")
      setReplyMessage(null)
      onWrite(false);
    }
  }

  const getRoleIcon = (role:string) => {
    switch (role){
      case "operative": return ""
      case "spymaster": return <FaCrown />
      default: return "";
    }
  }

  const findQMessage = (message:any):any => {
    return messages.find((m:any) => m.id == message.quoteID);
  }

  const onKeyUp = (event:any) => {
    if (event.keyCode === 13) {
      sendMessage(event);
    } else {
      if (!typing) {
        setTyping(true);
        setTimeout(function () {
          setTyping(false);
        }, 1000);
        onWrite(true);
      }
    }
  }

  return (
    <section className={"chat" + (replyMessage != null ? " with-reply-box" : "")}>
            <div className="write-box">
              {typingMap.length > 0 ? <ul>
                Píše {typingMap.map((x:any) => x.name)
                  .toSorted((a:any,b:any) => a.localeCompare(b))
                  .map((u:any) => <li key={u}>{u}</li>)
              }
              </ul> : ""}
            </div>
            <ul onScroll={handleScroll} ref={listRef}>
                { messages.map( (m:any) => <li key={m.id} className={"authc-" + m.authorColor + " authr-" + m.authorRole}>
                    { m.quoteID ? <div className={"quote qc-" + findQMessage(m).authorColor + " qr-" + findQMessage(m).authorRole}><span className='authorQ'>{findQMessage(m).author}:</span> <TextLink text={findQMessage(m).message} /></div> : null }
                    <span className="author" title={m.dateTime} onClick={e => { setReplyMessage(m); chatInput.current?.focus() }}>{getRoleIcon(m.authorRole)} {m.author}</span> <TextLink text={m.message} />
                  </li> ) }
                  <div ref={endOfMessagesRef} />
            </ul>
            <div className="reply-box">
                <button title="Zrušit odpověď na zprávu" onClick={e => setReplyMessage(null)}><FaWindowClose /></button>
                <span>{replyMessage ? replyMessage.author : ""}:</span> {replyMessage ? replyMessage.message : ""}
            </div>
            <div className="chat-box">
                <form onSubmit={ e => e.preventDefault() }> { /* onSubmit={e => sendMessage(e) } */ }
                    <input type="text" name="message" value={message} ref={chatInput}  autoComplete="off" title="Napište zprávu všem oststním do chatu" onChange={ e => setMessage(e.target.value) } onKeyUp={e => onKeyUp(e) } />
                    <input type="button" value="Odeslat" title="Odeslat zprávu do chatu" onClick={e => sendMessage(e) }/>
                </form>
            </div>
        </section>
  )
}

export default Chat
