import React/*, { useEffect, useState }*/ from 'react';
import Field from './Field';
import './WordTable.css';


function WordTable({ data, doAction }: any) {

    if (data == null) {
        return ( <div></div> )
    }
    const currentPlayer = data.currentPlayer;
    return (
        <section className="word-table">
            <ul>
                { data.fields.map((f: any, index: number) => <Field field={f} currentPlayer={currentPlayer} state={data.state} key={index} doAction={doAction} />) }
            </ul>
        </section>
    );
}
export default WordTable;



