import * as signalR from "@microsoft/signalr";

const URL = window.location.port != "3000" ? "/hub" : "https://localhost:5001/hub";

class Connector {
    private connection: signalR.HubConnection;
    public events: (
        onUpdateStateReceived: (messageType: string, message: string) => void,
        onNewChatMessageReceived: (messageType: string, message: string) => void,
        onTyping: (name: string, state: boolean) => void,
        onInit: () => void
    ) => void;
    public eventsOff: () => void;
    
    static instance: Connector;
    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL)
            .withAutomaticReconnect()
            .build();
        this.connection.start().catch(err => document.write(err));
        this.events = (onUpdateStateReceived, onNewChatMessageReceived, onTyping, onInit) => {
            this.connection.on("updateState", (messageType, message) => {
                onUpdateStateReceived(messageType, message);
            });
            this.connection.on("chatMessage", (messageType, message) => {
                onNewChatMessageReceived(messageType, message);
            });
            this.connection.on("typing", (name, state) => {
                onTyping(name, state);
            });
            this.connection.on("init", () => {
                onInit();
            });
        };
        this.eventsOff = () => {
            this.connection.off("updateState");
            this.connection.off("chatMessage");
            this.connection.off("typing");
            this.connection.off("init");
        };
    }
    public newMessage = (method: string, messageType: string, message: any) => {
        this.connection.send(method, messageType, JSON.stringify(message)).then(x => {} /* console.log("sent") */)
    }
    public static getInstance(): Connector {
        if (!Connector.instance)
            Connector.instance = new Connector();
        return Connector.instance;
    }
}

export default Connector.getInstance;
